.nav_link {
  text-align: center;
}

.nav_link a {
  font-family: "Bebas Neue";
  font-weight: bold;
  font-size: 2.4rem;
  color: #000747 !important;
}

.nav_link div {
  width: 100%;
  text-align: center;
  border-radius: 0.3rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #b9dedc;
}

.notification_close_icon {
  width: 2rem;
  height: 2rem;
  align-self: center;
}
