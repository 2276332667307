.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.8rem 2rem;

  &_navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 1 60%;
    gap: 1.7rem;

    & a {
      text-decoration: none;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
}
