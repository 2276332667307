.map-container {
  height: 400px;
  text-align: center;
  width: 80% !important;
}

ion-icon {
  --ionicon-stroke-width: 2.8rem;
}

.carousel-inner {
  max-height: 40vw;
}

@media only screen and (max-width: 1000px) {
  .mapboxgl-map {
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 770px) {
  .mapboxgl-map {
    width: 500px !important;
    height: 350px !important;
  }
}

@media only screen and (max-width: 540px) {
  .mapboxgl-map {
    width: 350px !important;
    height: 200px !important;
  }
}
