.salaahGridContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: #708238;
  padding: 2rem;
  border-radius: 10px;
  justify-items: center;
}

.salaahGrid {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: 15rem 15rem;
  grid-gap: 2rem;
}

.imgStyles {
  width: 30vw;
}

@media only screen and (max-width: 1200px) {
  .imgStyles {
    display: none;
  }

  .salaahGridContainer {
    display: block;
  }

  .salaahGrid {
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .salaahGrid {
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: 10rem 10rem 10rem;
  }
}

@media only screen and (max-width: 470px) {
  .salaahGrid {
    grid-gap: 0.8rem;
  }
}
