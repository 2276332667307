$primary: #ffd700;
$secondary: #708238;
$success: #8fb430;
$light: #fff;
$danger: #ff5252;
$info: #730073;
$warning: #4c4cff;

$font-size-root: 10px;

// #fcc80b
// $theme-colors: (
//     "primary": #ffd700,
//     "secondary": #708238,
//     "more": #808000,
//     "info": #ffd700,
//     "plain": #fff,
//     "black": #000,
// );
