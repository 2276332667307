.khutbah_current {
  width: 100%;
  height: 48rem;
}

.khutbah__video {
  display: grid;
  grid-template-columns: max-content 1fr;
  cursor: pointer;
  padding: 0.5rem;

  &--text {
    padding-top: 0.6rem;
    margin-left: 2rem;
  }
}

.khutbah__video--thumbnail {
  clip-path: polygon(0% 12%, 100% 12%, 100% 88%, 0% 88%);
  border-radius: 1.1rem;
  width: 13rem;
}

.video__active {
  color: white;
}

@media screen and (max-width: 768px) {
  .khutbah_current {
    height: 30rem;
  }
}

@media screen and (max-width: 567px) {
  .khutbah_current {
    height: 30rem;
  }

  .pagination {
    justify-content: center;
  }

  .grid-container {
    padding: 0rem 0.2rem 0rem 0.2rem !important;
  }
}

@media screen and (max-width: 475px) {
  .khutbah__video {
    margin-bottom: 0rem;

    &--thumbnail {
      width: 100%;
      border-radius: 0.4rem;
    }
    &--title {
      font-size: 1rem;
    }

    &--text {
      margin-left: 0.5rem;
    }

    &--description {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 375px) {
  .khutbah_current {
    height: 24rem;
  }
  .khutbah__video {
    &--title {
      font-size: 1rem;
    }

    &--description {
      display: none;
    }
  }
}
