.imgContainerOne {
  background-image: url("../../../public/images/Nur-E-Islam-1-dark.jpg");
  background-size: cover;
  height: 53rem;
}

.imgContainerTwo {
  background-image: url("../../../public/images/Nur-E-Islam-2-dark.jpg");
  background-size: cover;
  height: 53rem;
}

.imgContainerThree {
  position: relative; /* Ensure the container is positioned */
  background-image: linear-gradient(rgba(95, 95, 95, 0.3), rgba(95, 95, 95, 0.3)),
    url("../../../public/images/Nur-E-Islam-3-dark.jpg");
  background-size: cover;
  height: 53rem;
}

.imgContainerThree::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5 for 50% darkness) */
}
