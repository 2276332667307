.headingMedium {
  color: white;
  text-align: center;
  position: relative;
  width: 50%;
  margin: 2rem auto;
}

.headingMedium::after {
  content: "";
  width: 100%;
  margin: 0.6rem auto;
  border-bottom: solid 1px yellow;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
}
.iconContainer {
  margin-bottom: 2rem;
  text-align: center;
}
