.create-event {
    &--container {
        width: 75%;
        background-image: url("../public/images/event-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }

    &--form {
        // height: 35rem;
        background-color: rgba(255, 255, 255, 0.511);
    }
}

.text_input {
    &--field {
        width: 60%;
        border-radius: 1rem;
    }

    &--container {
        position: relative;
        width: 65%;
    }

    &--error {
        color: red;
        position: absolute;
        transform: translateY(-55%);
        font-size: 0.9rem;
    }
}
