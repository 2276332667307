.auth_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.auth_heading::before,
.auth_heading::after {
  content: "";
  display: inline-block;
  flex-grow: 1;
  height: 0.35rem;
  background-image: linear-gradient(to right, #f7065d, #cccccc);
}
