.card {
  height: 30rem;
}

.campaign_container img {
  width: 50%;
}

.campaignBtn {
  background-color: #9389f4;
  color: black;
  width: 18rem;
  height: 5rem;
  font-size: 2rem;
  font-weight: 600;
}

.campaignHeading {
  font-weight: 700;
  color: white;
  margin-bottom: 2rem;
}

.campaignBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.element_seperator {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.element_seperator::before,
.element_seperator::after {
  content: "";
  flex: 1 1;
  border-bottom: 10px solid #9389f4;
  margin: auto 0rem;
}

@media only screen and (max-width: 770px) {
  .campaignBtn {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
