@font-face {
  font-family: "Bebas Neue";
  src: url(../src/resources/fonts/Bebas_Neue/BebasNeue-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url(../src/resources/fonts/Bebas_Neue/BebasNeue-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

//Nunito
@font-face {
  font-family: "Nunito";
  src: url(../src/resources/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url(../src/resources/fonts/Nunito/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url(../src/resources/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

// $utilities: (
//   "font-family": (
//     property: font-family,
//     class: font,
//     values: (
//       primary: "Bebas Neue",
//       secondary: "Nunito",
//     ),
//   ),
//   "font-size": (
//     property: font-size,
//     class: fs,
//     values: (
//       0: 7.2rem,
//       1: 4.8rem,
//       2: 3.2rem,
//       3: 2.4rem,
//       4: 1.8rem,
//       5: 1.6rem,
//     ),
//   ),
//   "font-weight": (
//     property: font-weight,
//     class: fw,
//     values: (
//       normal: 400,
//       bold: 700,
//       semi-bold: 600,
//       bolder: 800,
//     ),
//   ),
// );
