$duration: 20s;

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 3rem;
  padding-left: 100%; // offset items to begin
}

.ticker {
  display: inline-block;
  height: 2rem;
  white-space: nowrap;
  padding-right: 100%; // ensure items go before animations repeat (taken from parent due to inline-block)
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: $duration;

  &__item {
    display: inline-block;

    color: #fff;
    margin-top: 0.3rem;
  }

  &__seperator {
    color: white;
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: sub;
  }
}
