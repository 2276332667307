.salaahCardContainer {
  border: 2px dashed #abc35f;
  padding: 0.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
}

.activeSalaah {
  color: black;
  background-color: #abc35f;
}

.iqamah {
  border-top: 2px #abc35f dashed;
  width: 100%;
  text-align: center;
  padding-top: 4px;
}

.iqamahActive {
  background-color: #5e7025;
  color: white;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 8px;
}

@media only screen and (max-width: 470px) {
  .iqamah {
    font-size: 0.8rem;
  }
}
